<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container-fluid">
          <formBuilder/>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    formBuilder () { return import('@/components/formBuilder/list.vue') }
  },
  data () {
    return {
      data: false
    }
  },
  methods: {

  },
  mounted () {
  }
}
</script>
